function Contact()
{
    return (
        <>
        <div>
            
        </div>
        </>
    );
}

export default Contact;