import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import  Home  from './components/Home'
import  Contact  from './components/Contact'
import  Projects  from './components/Projects'
import TopNavigationBar from './components/TopNavigationBar1'

const theme = createTheme({
  palette: {
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000',
      contrastText: '#000000', 
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
  },  
  components:{
    MuiDrawer:{
      styleOverrides: {
        paper: {
          backgroundColor: "#808080",
          color: "#ffffff",
        }
      }
    }
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyBhNNODXWdy9IVKPE20KuN70lTEIS2dJbQ",
  authDomain: "robertomilla-734e9.firebaseapp.com",
  projectId: "robertomilla-734e9",
  storageBucket: "robertomilla-734e9.appspot.com",
  messagingSenderId: "611306286164",
  appId: "1:611306286164:web:04f8de130faebc1e7b51c1",
  measurementId: "G-0QXDERQ5YP"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    	 <Router>
        <ThemeProvider theme={theme}>
       <div className="App">
        <Routes>
                <Route exact path='/Home' element={< Home />}></Route>
                <Route exact path='/Projects' element={< Projects />}></Route>
                <Route exact path='/Contact' element={< Contact />}></Route>
        </Routes>
        <TopNavigationBar></TopNavigationBar>
        </div>
        </ThemeProvider>
       </Router>
  );
}

export default App;